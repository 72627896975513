import { getData, postData } from './../../app/appdomain'
import { appAdapter } from '../../app/appadaper'
export default class CPUserInfo {

  getVisitorToken() {
    return new Promise(function (resolve, reject) {
      getData("/qrCode/createLoginToken", {}, false)
        .then(res => {
          if (resolve) {
            resolve(res.data);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
  getQrCodeCheckLogin(qrCode) {
    return new Promise(function (resolve, reject) {
      postData("/qrCode/checkLogin", qrCode, false)
        .then(res => {
          if (resolve) {
            resolve(res);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
  // 上传视频时的地区
  reqeustLocalInfo() {
    return new Promise(function (resolve, reject) {
      getData("/home/list/localInfo", {}, true)
        .then(res => {
          if (resolve) {
            resolve(res.data);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
  //  上传的金额套餐
  uploadVideoPackage(parms) {
    return new Promise(function (resolve, reject) {
      getData("/free/videoPackage", parms, true)
        .then(res => {
          if (resolve) {
            resolve(res.data);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
  //保存视频
  getReqeustSaveMov(params) {


    let newParams = {};
    let values = Object.keys(params);

    values.forEach(item => {
      let val = params[item];
      if (val !== -1) {
        newParams[item] = val;
      }
    });
    return new Promise(function (resolve, reject) {
      postData("/movUser/saveMov", newParams)
        .then(res => {
          if (resolve) {
            resolve(res.data);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
  // 上传视频时的分类
  reqeustCls() {
    return new Promise(function (resolve, reject) {
      getData("/home/cls/query", {}, true)
        .then(res => {
          if (resolve) {
            resolve(res.data);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
  // 所有的标签
  requestAllTag() {

    return new Promise(function (resolve, reject) {
      getData("/tag/list2", {})
        .then(res => {
          if (resolve) {
            resolve(res.data);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
  userLogin(callback) { // 用户信息获取
    return new Promise(function (resolve, reject) {
      getData("/user/info", {}, false)
        .then(res => {

          if (resolve) {
            appAdapter().getStore().storeWappUser(res.data.data[0]);
            // setStorageSync('wapp_user',res.data[0]);
            callback&&callback();
            resolve(res.data);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
  getReqeustIsPayRatio(parms) { //获取付费上传比例
    return new Promise(function (resolve, reject) {
      getData("/isPayRatio", parms, false)
        .then(res => {

          if (resolve) {
            resolve(res.data);
          }
        })
        .catch(err => {
          if (reject) {
            reject(err);
          }
        });
    });
  }
}